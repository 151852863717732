<template>
  <div class="MiniCart">
    <v-sheet
      @click="handleClick()"
      tile
      dark
      height="60"
      class="d-flex justify-space-between pa-4"
    >
      <div class="total">
        <v-img
          class="mr-2"
          max-width="30"
          src="../assets/svg/hell_pontok.svg"
        ></v-img>
        <span>{{ remainingPoints }}</span>
      </div>
      <div class="total">
        <v-icon color="primary">mdi-cart</v-icon>
        <span class="mr-2">{{ this.items.length }}</span>
        <v-img
          class="mr-2"
          max-width="30"
          src="../assets/svg/hell_pontok.svg"
        ></v-img>
        <span>{{ cartTotal }}</span>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
export default {
  name: "MiniCart",
  data() {
    return {};
  },
  methods: {
    handleClick() {
      if (this.items.length > 0) {
        this.$router.push("/kosar");
      }
    },
  },
  computed: {
    items() {
      return this.$store.getters.getCart;
    },
    user() {
      return this.$store.getters.getUser;
    },
    totalPoints() {
      return this.$store.getters.totalPoints;
    },
    cartTotal() {
      let total = 0;
      this.items.forEach((item) => {
        total += item.price * item.count;
      });
      return total;
    },
    remainingPoints() {
      return this.totalPoints - this.cartTotal;
    },
  },
};
</script>
<style scoped>
.TextSearch {
  /*min-width: 100%;*/
}
.total {
  display: flex;
  align-items: center;
}
</style>
