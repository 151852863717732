<template>
  <div class="ProductView">
    <div class="cart-bar">
      <mini-cart></mini-cart>
    </div>
    <div class="search-product pr-4 pl-4">
      <v-text-field
        @keydown.enter="doSearch()"
        :label="$t('app.search')"
        v-model="keyword"
        dark
      >
        <template v-slot:prepend>
          <v-btn tile icon dark class="ma-0" @click="doSearch()">
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>
    <v-card
      class="ml-4 mr-4"
      v-if="!isNewItem && !isEditItem"
      max-height="100vh"
      style="padding-bottom: 50vh"
      color="transparent"
    >
      <ProductTeaser
        v-for="item in data"
        :item="item"
        :key="item.id"
      ></ProductTeaser>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-card>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("app.deleteWarning") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn @click="finalize()" color="red lighten-1">
            {{ $t("app.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
import ProductTeaser from "../components/ProductTeaser.vue";
import MiniCart from "../components/MiniCart.vue";
export default {
  name: "Products",
  components: {
    ProductTeaser,
    MiniCart,
  },
  data() {
    return {
      clients: [],
      data: [],
      dialog: false,
      activeItem: null,
      isNewItem: false,
      isEditItem: false,
      keyword: null,
    };
  },
  created() {
    this.getList(null);
    eventBus.$on("DELETE_ITEM", (item) => {
      this.deleteItem(item);
    });
    eventBus.$on("CANCEL_PRODUCT_ITEM", () => {
      this.isNewItem = false;
      this.isEditItem = false;
      this.activeItem = null;
      this.getList(null);
    });
    eventBus.$on("EDIT_ITEM", (item) => {
      this.editItem(item);
    });
    eventBus.$on("REFRESH", () => {
      this.getList(null);
    });
  },
  mounted() {
    eventBus.$emit("RIGHT_NAV", "CART");
  },
  destroyed() {
    eventBus.$off("DELETE_ITEM");
    eventBus.$off("CANCEL_PRODUCT_ITEM");
    eventBus.$off("EDIT_ITEM");
    eventBus.$off("REFRESH");
    eventBus.$emit("RIGHT_NAV", "DEFAULT");
  },
  methods: {
    doSearch() {
      this.getList(this.keyword);
    },
    getList(keyword) {
      let q = "";
      if (keyword) {
        q = keyword;
      }
      this.$store
        .dispatch("getCollection", {
          end: `client/products/filtered?keyword=${q}`,
          payload: null,
        })
        .then((res) => {
          this.data = res;
        });
    },
    getMedia() {},
    deleteItem(item) {
      this.dialog = true;
      this.activeItem = item;
    },
    editItem(item) {
      this.isEditItem = false;
      this.activeItem = item;
      this.isEditItem = true;
    },
    finalize(item) {
      this.$store
        .dispatch("removeItem", {
          end: `admin/products/${this.activeItem.id}`,
          data: null,
        })
        .then((res) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
          this.activeItem = null;
          this.dialog = false;
        });
    },
    addNewItem() {
      this.isNewItem = true;
    },
  },
};
</script>
<style scoped>
.tile-grid {
  display: flex;
  flex-wrap: wrap;
}
.rscope {
  position: relative;
}
.absRight {
  position: absolute;
  right: 0;
}
.tile-grid > * {
  flex: 1 1 40%;
  margin: 1rem 1rem 2rem;
}
.cart-bar {
  position: fixed;
  bottom: 0;
  z-index: 5;
  left: 0;
  right: 0;
}
</style>
